import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { EyeFill, InfoCircleFill, ExclamationTriangleFill } from 'react-bootstrap-icons';

// Acceptable link
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";

import FloodAreasModal from './FloodAreasModal.js';
import FloodAttractionModal from './FloodAttractionModal.js';
import AdComponent from './AdComponent.js';

import { formatTimestamp } from '../lib/formatHelpers.js';

import { sendViewAreaOnMapClick, sendViewAreaInfoClick } from './GoogleAnalytics.js';

import statusInfo from '../lib/statusInfo.js';
const statusText = statusInfo.statusText;

function FloodAreas(props) {
  	const location = useLocation();
  	const [activeFAModel, setActiveFAModal] = useState(null);

  	let basePath = '/map';
  	if(props.basePath) {
  		basePath = props.basePath + "/map";
  	}

	useEffect(() => {
		if(typeof window !== 'undefined') {
			window.scrollTo(0, 0);
		}
	}, [location]);


	let areas = [];
	if(props.mapFloodAreas) {
		let unorderedKeys = Object.keys(props.mapFloodAreas);
		if(props.mapAttractions) {
			unorderedKeys = unorderedKeys.concat(Object.keys(props.mapAttractions));
		}
		let orderedKeys = unorderedKeys.sort(function(aKey, bKey) {
            let a = props.mapFloodAreas[aKey];
            let b = props.mapFloodAreas[bKey];
            if(!a) {
            	a = props.mapAttractions[aKey];
            }
            if(!b) {
            	b = props.mapAttractions[bKey];
            }
            if(a.name < b.name) {
                return -1;
            }
            if(a.name > b.name) {
                return 1;
            }
            return 0;
        });

		for(let mfaKey of orderedKeys) {
			let mfa = props.mapFloodAreas[mfaKey];
			let type = "MAP_FLOOD_AREA";
			if(!mfa) {
				mfa = props.mapAttractions[mfaKey];
				type = "MAP_ATTRACTION";
			}

			let styles = {};
			let classes = [];
			switch(mfa.status) {
				case "NONE":
				case "CLEAR":
					styles = {
						backgroundColor: (props.statusMappings && props.statusMappings['CLEAR'] && props.statusMappings['CLEAR'].color) || null
					};
					classes = ["text-white"];
					break;
				case "RECENTLY_FLOODED":
					styles = {
						backgroundColor: (props.statusMappings && props.statusMappings['RECENTLY_FLOODED'] && props.statusMappings['RECENTLY_FLOODED'].color) || null
					};
					classes = ["text-white"];
					break;
				case 'ALERT':
				case "IN_PARTIAL_FLOOD":
					styles = {
						backgroundColor: (props.statusMappings && props.statusMappings['IN_PARTIAL_FLOOD'] && props.statusMappings['IN_PARTIAL_FLOOD'].color) || null
					};
					classes = ["text-white"];
					break;
				case "IN_FLOOD":
				case "CLOSED":
					styles = {
						backgroundColor: (props.statusMappings && props.statusMappings['IN_FLOOD'] && props.statusMappings['IN_FLOOD'].color) || null
					};
					classes = ["text-white"];
					break;
				default:
					break;
			}

			let primaryColours = "bg-dark text-white";
			if(!!props.secondaryMode) {
				primaryColours = "bg-secondary text-white";
			}

			let openModalFn = function() {
				setActiveFAModal(mfaKey);
				sendViewAreaInfoClick(props.mapName, mfa.name)
			};

			if(type === "MAP_FLOOD_AREA") {
				let expectedToFlood = !!mfa.metadata.expectedToFlood;

				let modalPopupButton = <Button variant="secondary" size="sm" onClick={openModalFn}>
											<InfoCircleFill/> View More Info
										</Button>;
				if(expectedToFlood) {
					modalPopupButton = <Button variant="warning" size="sm" onClick={openModalFn}>
										  <ExclamationTriangleFill/> View More Info
										</Button>;
				}
				if(mfa.status === "IN_FLOOD" || mfa.status === "IN_PARTIAL_FLOOD") {
					modalPopupButton = <Button variant="danger" size="sm" onClick={openModalFn}>
										  <ExclamationTriangleFill/> View More Info
										</Button>;
				}


				let floodAlert = [];
				if(mfa.status !== "IN_PARTIAL_FLOOD" && (mfa.metadata.expectedToFlood === "PARTIAL" || mfa.metadata.expectedToFlood === "FULLY") && !!mfa.metadata.expectedToPartialFloodTimestamp) {
					floodAlert.push(<Row key={"partial"} className="text-white">
							<Col xs={1}><b><ExclamationTriangleFill className="text-warning" /></b></Col>
							<Col xs={5}>{formatTimestamp(mfa.metadata.expectedToPartialFloodTimestamp)}</Col>
							<Col xs={6}><b><center>BEGINS FLOODING</center></b></Col>
						</Row>);
				}
				if(mfa.metadata.expectedToFlood === "FULLY" && !!mfa.metadata.expectedToFullyFloodTimestamp) {
					floodAlert.push(<Row key={"fully"} className="text-white">
							<Col xs={1}><b><InfoCircleFill className="text-danger" /></b></Col>
							<Col xs={5}>{formatTimestamp(mfa.metadata.expectedToFullyFloodTimestamp)}</Col>
							<Col xs={6}><b><center>FULLY FLOODED</center></b></Col>
						</Row>);
				}
				if(
					(mfa.metadata.expectedToFlood === "PARTIAL" || mfa.metadata.expectedToFlood === "FULLY")
				 && floodAlert.length === 0
				) {
					floodAlert.push(<Row key={"fully"} className="text-white">
						<Col xs={2}><b><InfoCircleFill className="text-danger" /></b></Col>
						<Col xs={10}><b><center>FLOODING FORECASTED</center></b></Col>
					</Row>);
				}


				let statusSubComponent = <b>{statusText[mfa.status] || "Unknown"}</b>;
				if(mfa.attributes && mfa.attributes.isCauseway) {
					if(mfa.status === "IN_FLOOD" || mfa.status === "IN_PARTIAL_FLOOD") {
						statusSubComponent = <b>Estimated Height: {parseFloat(mfa.metadata.floodingCurrentHeight).toFixed(2)}m (+/- 0.05m)</b>;
					}
				}

				// This is not the same as the attraction below, don't change one expecting both to change.
				areas.push(<Row key={mfaKey} style={{paddingBottom: "4px"}}>
					<Col xs={12} xl={2} xxl={3} className={primaryColours}><b>{mfa.name}{props.includeIconWithName && " "}{props.includeIconWithName && props.includeIconWithName}</b></Col>
					<Col xs={12} xl={4} xxl={4} className={classes.join(", ")} style={styles}><i>{mfa.description}</i></Col>
					<Col xs={12} xl={2} xxl={1} className={classes.join(", ")} style={styles}>
						{statusSubComponent}
					</Col>
					<Col xs={12} xl={4} xxl={4} className={classes.join(", ")} style={styles}>
						{!props.overrideButtonViewHandler && <Link to={`${basePath}/${mfa.urlSlug}`} onClick={function() {
							sendViewAreaOnMapClick(props.mapName, mfa.name);
						}}>
							<Button variant="dark" size="sm"><EyeFill /> View area on Map</Button>
						</Link>}
						{!!props.overrideButtonViewHandler && <Button variant="dark" size="sm" onClick={props.overrideButtonViewHandler}><EyeFill /> View area on Map</Button>}
						{" "}
						{modalPopupButton}
					</Col>
					<FloodAreasModal
						activeFAModel={activeFAModel}
						baseStations={props.baseStations}
						mfaKey={mfaKey}
						mfa={mfa}
						statusText={statusText}
						classes={classes}
						styles={styles}
						floodAlert={floodAlert}
						mapFloodDefences={props.mapFloodDefences}
						displayStation={props.displayStation}
						onHide={function() {
							setActiveFAModal(null);
						}}
					/>
				</Row>);
			} else if(type === "MAP_ATTRACTION") {
				let modalPopupButton = <Button variant="danger" size="sm" onClick={openModalFn}>
				  <ExclamationTriangleFill/> View More Info
				</Button>;

				if(mfa.status === 'ALERT') {
					modalPopupButton = <Button variant="warning" size="sm" onClick={openModalFn}>
				  <ExclamationTriangleFill/> View More Info
				</Button>;
				} else if(mfa.status !== "CLOSED") {
					modalPopupButton = <Button variant="secondary" size="sm" onClick={openModalFn}>
					  <InfoCircleFill/> View More Info
					</Button>;
				}


				areas.push(<Row key={mfaKey} style={{paddingBottom: "4px"}}>
					<Col xs={12} xl={2} xxl={3} className={primaryColours}><b>{mfa.name}{props.includeIconWithName && " "}{props.includeIconWithName && props.includeIconWithName}</b></Col>
					<Col xs={12} xl={4} xxl={4} className={classes.join(", ")} style={styles}><i>{mfa.title}</i></Col>
					<Col xs={12} xl={2} xxl={1} className={classes.join(", ")} style={styles}>
						<b>{statusText[mfa.status] || "Unknown"}</b>
					</Col>
					<Col xs={12} xl={4} xxl={4} className={classes.join(", ")} style={styles}>
						{!props.overrideButtonViewHandler && <Link to={`${basePath}/${mfa.urlSlug}`} onClick={function() {
							sendViewAreaOnMapClick(props.mapName, mfa.name);
						}}>
							<Button variant="dark" size="sm"><EyeFill /> View area on Map</Button>
						</Link>}
						{!!props.overrideButtonViewHandler && <Button variant="dark" size="sm" onClick={props.overrideButtonViewHandler}><EyeFill /> View area on Map</Button>}
						{" "}
						{modalPopupButton}
					</Col>
					<FloodAttractionModal
						activeFAModel={activeFAModel}
						mfaKey={mfaKey}
						mfa={mfa}
						statusText={statusText}
						classes={classes}
						styles={styles}
						onHide={function() {
							setActiveFAModal(null);
						}}
					/>
				</Row>);
			}
		}
	}

	if(areas.length > 10 && process.env.REACT_APP_ENABLE_GOOGLE_ADS_ON_FLOODNAV === "true") {
		/*
		<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-fb+5w+4e-db+86"
     data-ad-client="ca-pub-6790224688210339"
     data-ad-slot="6130981811"></ins>*/
		areas.splice(
			9,
			0,
			<Row>
				<Col xs={12}>
				<AdComponent
					  client="ca-pub-6790224688210339"
					  slot="6130981811" // Replace this with your actual data-ad-slot value
					  format="fluid"
					  layoutKey="-fb+5w+4e-db+86" // Adding a new prop for data-ad-layout-key
					  responsive={true} // Assuming you want it responsive; adjust as necessary
					/>
				</Col>
			</Row>
		);
	}

	return (
		<div>{areas}</div>
	);
}

export default FloodAreas;